import type { InjectionKey } from 'vue'

// models
import { PresentWithItemData } from '@/models/present'

// composables
import { useRepositoryFactory } from '@/composables/repository/useRepositoryFactory'
import {
  GetPresentsRequest,
  getPresentsResponse,
  OpenPresentRequest,
} from '@/composables/repository/usePresentRepository'

// modules
import { isValueOf } from '@/utils/zod'

export type PresentStateType = {
  presentList: PresentWithItemData[]
}

export const usePresent = () => {
  const repositoryFactory = useRepositoryFactory()
  const presentRepository = repositoryFactory.get('present')
  const cloudStoreRepository = repositoryFactory.get('cloudStore')

  const state = useState<PresentStateType>('present', () => ({
    presentList: [],
  }))

  // pager情報
  const limit = ref(20)
  const offset = ref(0)
  const total = ref(0)
  const page = ref(0)

  /**
   * プレゼントの一覧を取得する
   */
  const getPresents = async (options?: {
    isOpened?: boolean
    page?: number
  }) => {
    page.value = options?.page || 1
    offset.value = page.value * limit.value - limit.value

    const params: GetPresentsRequest = {
      limit: limit.value,
      offset: offset.value,
    }

    if (typeof options?.isOpened === 'boolean') {
      params.isOpened = options.isOpened
    }

    const response = await presentRepository.get.getPresents(params)

    if (!response) {
      throw new Error('response is empty.')
    }

    if (!isValueOf(getPresentsResponse, response)) {
      console.error('An API response is different.')
    }

    const presentList: PresentWithItemData[] = response.presents

    // ストアアイテムの詳細情報取得
    const targetStoreItemIds = presentList
      .map((present) => present.presentStoreItem?.storeItemId)
      .filter((id): id is number => typeof id === 'number')
    await cloudStoreRepository.post
      .postItemList({ itemIds: targetStoreItemIds })
      .then((response) => {
        if (!response) {
          throw new Error('response is empty.')
        }

        presentList.forEach((present) => {
          const storeItem = response.items.find(
            (itemPurchased) =>
              itemPurchased.item.id === present.presentStoreItem?.storeItemId
          )
          if (storeItem) {
            present.storeItem = storeItem.item
          }
        })
      })

    state.value.presentList = presentList
    total.value = response.presentCount
  }

  /**
   * プレゼントを受け取る
   */
  const openPresent = async (id: string | number) => {
    const response = await presentRepository.patch.openPresent(id)

    if (!response) {
      throw new Error('response is empty.')
    }
  }

  return {
    state: readonly(state),
    limit,
    offset,
    total,
    page,
    getPresents,
    openPresent,
  }
}

export type PresentComposable = ReturnType<typeof usePresent>

export const presentComposablesInjectionKey: InjectionKey<PresentComposable> =
  Symbol('present-composable')
